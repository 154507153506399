@import "src/themes/mojito/styles/index.scss";
.layout {
  width: 100%;

  &--no-scroll {
    position: fixed;

    @include md {
      position: initial;
    }
  }

  &--dashboard {
    height: 100vh;
    width: 100%;
    background-color: getvar($colors, "neutral", "50");

    @include md {
      display: flex;
      overflow: hidden;
    }

    .hamburger__links-wrapper {
      background-color: getvar($colors, "neutral", "white");

      @include md {
        background-color: inherit;
        padding: inherit;
      }
    }

    main {
      flex: 1;
      overflow: hidden;
      @include sm {
        overflow: auto;
      }
    }
  }

  &__wrapper {
    @extend .u-spacing-8;
    margin-bottom: 0;

    &--Mt {
      margin-top: space(1.5);

      @include md {
        margin-top: space(3);
      }
    }
  }
}
